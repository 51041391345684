<template>
  <v-container grid-list>
    <v-layout>
      <v-flex xs12>
        <v-card>
          <v-card-title primary-title>
            <h3 class="headline mb-0">Histórico</h3>
          </v-card-title>
          <Grid
            :style="{ height: 'auto' }"
            :data-items="Items"
            :columns="Columns"
            :pageable="true"
            :skip="skip"
            :take="take"
            :total="grid.pagging.total"
            @pagechange="pageChange"
            :sortable="false"
            :filterable="false"
          >
            <GridNoRecords>Não existe histórico</GridNoRecords>
          </Grid>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import instancesService from "@/services/api/usersService";
import GridHelper from "@/utilities/grid";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
export default {
  data() {
    return {
      Instance: {},
      grid: {
        sort: [],
        filter: { logic: "and", filters: [] },
        pagging: {
          pageId: 1,
          pageSize: 10,
          total: 0,
        },
      },
      Items: [],
      Columns: [
        {
          field: "Date",
          title: "Data",
          width: 150,
          sortable: false,
        },
        {
          field: "Type",
          title: "Tipo",
          width: 200,
          sortable: false,
        },
        {
          field: "Notes",
          title: "Descrição",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({ user: types.GET_USER }),
    skip() {
      return (this.grid.pagging.pageId - 1) * this.grid.pagging.pageSize;
    },
    take() {
      return this.grid.pagging.pageSize;
    },
  },
  async created() {
    await this.readUserLogs();
  },
  methods: {
    pageChange(ev) {
      this.grid.pagging.pageId = ev.page.skip / ev.page.take + 1;
      this.readUserLogs();
    },
    async readUserLogs() {
      let options = GridHelper.ConvertOptionsFromNativeKendoUIGrid(
        this.grid.pagging
      );
      let data = GridHelper.ParseOptions(options);
      data.Filters = [
        ...data.Filters,
        { Field: "IdUser", Operation: "eq", Value: this.user.IdUser },
      ];
      data.Order = [{ Field: "Date", Direction: "DESC" }];
      await instancesService
        .GetUserLogs(data)
        .then((res) => {
          this.Items = res.data.Items;
          this.grid.pagging.total = res.data.Total;
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    reloadGrid() {
      this.datasource.read();
    },
  },
};
</script>

<style></style>
