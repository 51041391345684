<template>
  <v-container grid-list>
    <v-layout>
      <v-flex xs6>
        <v-card>
          <v-form @submit.prevent="updateUserPassword">
            <v-card-title primary-title>
              <h3 class="headline mb-0">Dados de segurança</h3>
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-model="Password"
                label="Password"
                autocomplete="false"
                @input="$v.Password.$touch()"
                @blur="$v.Password.$touch()"
                type="password"
              ></v-text-field>
              <v-text-field
                label="Repita a password"
                v-model="RepeatPassword"
                autocomplete="false"
                type="password"
                @input="$v.RepeatPassword.$touch()"
                @blur="$v.RepeatPassword.$touch()"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn
                class="ml-auto"
                :disabled="$v.$anyError"
                :loading="isLoading"
                color="accent"
                type="submit"
                >Alterar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { sameAs, minLength } from "vuelidate/lib/validators";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import usersService from "@/services/api/usersService";
export default {
  data() {
    return {
      isLoading: false,
      Password: "",
      RepeatPassword: "",
    };
  },
  validations: {
    Password: {
      minLength: minLength(6),
    },
    RepeatPassword: {
      sameAsPassword: sameAs("Password"),
    },
  },
  methods: {
    async updateUserPassword() {
      this.$v.$touch();
      if (!this.$v.$anyError) {
        if (!this.isLoading) {
          this.isLoading = true;
          await usersService
            .UpdateSecurity(this.Password)
            .then(() => {
              notificationServiceBus.showSuccessMessage(
                "Successo!",
                "Editou os seus dados com sucesso!"
              );
              this.isLoading = false;
              this.Password = "";
              this.RepeatPassword = "";
            })
            .catch((error) => {
              this.isLoading = false;
              notificationServiceBus.showError(error.response);
            });
        }
      }
    },
  },
};
</script>

<style></style>
