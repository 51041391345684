<template>
  <div>
    <app-page-title>
      <h1>Ajuda</h1>
    </app-page-title>
    <v-container>
      <v-card>
        <v-card-title primary-title>
          <div class="w-100 text-center">
            Se sentir dificuldades ou necessitar de algum esclarecimento,
            contacte-nos por email.
          </div>
        </v-card-title>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  watch: {
    $route() {
      if (location.hash) {
        this.quickDialog = true;
      }
    },
  },
  mounted() {
    if (location.hash) {
      this.quickDialog = true;
    }
  },
};
</script>

<style></style>
