<template>
  <section v-if="user" id="MyAccountSecurity">
    <app-page-title>
      <h1>{{ user.Name }}</h1>
    </app-page-title>
    <div class="d-flex align-content-stretch detail-wrapper">
      <div class="detail-sidebar">
        <app-sidebar-menu :menus="menus" />
      </div>
      <div class="detail-content">
        <router-view params="route: route" />
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import PageTitleComponent from "@/components/layout/page-title";
import SidebarComponent from "@/components/layout/page-menu";

export default {
  components: {
    "app-page-title": PageTitleComponent,
    "app-sidebar-menu": SidebarComponent,
  },
  data() {
    return {
      menus: [
        {
          Name: "Meu Perfil",
          Icon: "icon-user",
          Path: "profile",
        },
        {
          Name: "Segurança",
          Icon: "icon-lock",
          Path: "security",
        },
        {
          Name: "Histórico",
          Icon: "icon-user-info",
          Path: "history",
        },
        // {
        //   Name: "Mensagens",
        //   Icon: "icon-message",
        //   Path: "messages"
        // }
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER,
    }),
  },
};
</script>

<style></style>
