<template>
  <div>
    <v-container class="pb-0">
      <div class="general-section--mb-sm">
        <h3 class="size--s21 text--dark font--title">Dashboard</h3>
      </div>
      <v-container fluid grid-list-xl class="pl-0 pr-0">
        <v-layout class="pb-3" row wrap v-if="this.user && this.user.IdUserRole && this.user.IdUserRole > 3">
          <v-flex xs12 md3 v-for="quickAction in quickActionsFiltered" :key="quickAction.title">
            <v-card v-if="quickAction.path === 'inscriptions' &&
              checkRoleAccess(quickAction.roles)
              " class="dark--text pointer" @click="openDialogCreateBatchInscription()">
              <v-card-title primary-title class="text-center justify-content-center px-2">
                <div>
                  <v-icon class="size--s40 my-3" :class="quickAction.icon"></v-icon>
                  <div class="headline"></div>
                  <h2>{{ quickAction.title }}</h2>
                  <p>{{ quickAction.subtitle }}</p>
                </div>
              </v-card-title>
              <v-divider light></v-divider>
              <v-card-actions class="pa-3 justify-content-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      <v-icon :class="quickAction.help_icon"></v-icon>
                      <span class="ml-2">{{ quickAction.help_text }}</span>
                    </span>
                  </template>
                  <span>{{ quickAction.help_description }}</span>
                </v-tooltip>
              </v-card-actions>
            </v-card>
            <v-card v-else-if="quickAction.path === 'classes' &&
              checkRoleAccess(quickAction.roles)
              " class="dark--text pointer" @click="openDialogCreateCourseClass()">
              <v-card-title primary-title class="text-center justify-content-center px-2">
                <div>
                  <v-icon class="size--s40 my-3" :class="quickAction.icon"></v-icon>
                  <div class="headline"></div>
                  <h2>{{ quickAction.title }}</h2>
                  <p>{{ quickAction.subtitle }}</p>
                </div>
              </v-card-title>
              <v-divider light></v-divider>
              <v-card-actions class="pa-3 justify-content-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      <v-icon :class="quickAction.help_icon"></v-icon>
                      <span class="ml-2">{{ quickAction.help_text }}</span>
                    </span>
                  </template>
                  <span>{{ quickAction.help_description }}</span>
                </v-tooltip>
              </v-card-actions>
            </v-card>
            <v-card v-else-if="checkRoleAccess(quickAction.roles)" class="dark--text pointer" @click="onQuickActionNavigate(quickAction)">
              <v-card-title primary-title class="text-center justify-content-center px-2">
                <div>
                  <v-icon class="size--s40 my-3" :class="quickAction.icon"></v-icon>
                  <div class="headline"></div>
                  <h2>{{ quickAction.title }}</h2>
                  <p>{{ quickAction.subtitle }}</p>
                </div>
              </v-card-title>
              <v-divider light></v-divider>
              <v-card-actions class="pa-3 justify-content-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      <v-icon :class="quickAction.help_icon"></v-icon>
                      <span class="ml-2">{{ quickAction.help_text }}</span>
                    </span>
                  </template>
                  <span>{{ quickAction.help_description }}</span>
                </v-tooltip>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout v-else row wrap>
          <v-flex>
            <v-card>
              <v-card-title @click="navigateTo('reports/formation-by-modules/module')" primary-title class="text-center justify-content-center px-2">
                <div>
                  <v-icon class="size--s40 my-3 icon-workflow"></v-icon>
                  <div class="headline"></div>
                  <h2>Formação por módulos</h2>
                </div>
              </v-card-title>
              <v-divider light></v-divider>
              <v-card-actions class="pa-3 justify-content-center" @click="navigateTo('help')">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      <v-icon class="icon-catalog-challenge"></v-icon>
                      <span class="ml-2">Ajuda sobre Relatórios</span>
                    </span>
                  </template>
                  <span>Poderá elaborar e/ou observar diversos relatórios
                    referentes à plataforma.</span>
                </v-tooltip>
              </v-card-actions>
            </v-card>
          </v-flex>

          <v-flex>
            <v-card>
              <v-card-title @click="navigateTo('reports/formation-by-hours/store')" primary-title class="text-center justify-content-center px-2">
                <div>
                  <v-icon class="size--s40 my-3 icon-reports"></v-icon>
                  <div class="headline"></div>
                  <h2>Horas de Formação</h2>
                </div>
              </v-card-title>
              <v-divider light></v-divider>
              <v-card-actions class="pa-3 justify-content-center" @click="navigateTo('help')">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      <v-icon class="icon-catalog-challenge"></v-icon>
                      <span class="ml-2">Ajuda sobre Relatórios</span>
                    </span>
                  </template>
                  <span>Poderá elaborar e/ou observar diversos relatórios
                    referentes à plataforma.</span>
                </v-tooltip>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-container>

    <v-container v-if="info != null">
      <!-- <div class="list__box shadow general-section--mb-sm">
      <v-layout>
        <v-flex xs4 class="br">
          <div class="list__box__details">
            <div class="d-flex-between list__box__details__item  bb-gray">
              <div>
                <h4 class="size--s18">Utilizadores</h4>
                <p>Total de utilizadores ativos</p>
              </div>
              <span class="size--s21 font-weight-bold deep-purple--text">
                {{ info.TotalActiveUsers }}
              </span>
            </div>
            <div class="d-flex-between list__box__details__item  bb-gray">
              <div>
                <h4 class="size--s18">Formações</h4>
                <p>Total de Formações</p>
              </div>
              <span class="size--s21 font-weight-bold primary--text">
                {{ info.TotalCourses }}
              </span>
            </div>
            <div class="d-flex-between list__box__details__item ">
              <div>
                <h4 class="size--s18">Subscrições</h4>
                <p>Total de subscrições ativas</p>
              </div>
              <span class="size--s21 font-weight-bold teal--text">
                {{ info.TotalActiveSubscriptions }}
              </span>
            </div>
          </div>
        </v-flex>
        <v-flex xs4 class="br">
          <div class="list__box__details">
            <div class="d-flex-between list__box__details__item">
              <div>
                <h4 class="size--s18">Performance Formações</h4>
                <p>Inscrições em Formações vs Formações concluídos</p>
              </div>
            </div>
            <kendo-chart
              :legend-position="'bottom'"
              :series="coursesChart.series"
              :theme="'material'"
            ></kendo-chart>
          </div>
        </v-flex>
        <v-flex xs4>
          <div class="list__box__details">
            <div class="d-flex-between list__box__details__item">
              <div>
                <h4 class="size--s18">Taxa de adopção</h4>
                <p>Utilizadores vs utilizadores em Formações</p>
              </div>
            </div>
            <kendo-chart
              :legend-position="'bottom'"
              :series="adoptionChart.series"
              :theme="'material'"
            ></kendo-chart>
          </div>
        </v-flex>
      </v-layout>
    </div> -->

      <v-layout v-if="this.user?.IdUserRole && this.user.IdUserRole > 3">
        <v-flex xs7>
          <v-card class="mr-3">
            <v-card-title primary-title>
              <h4 class="font--title font-weight-w500">
                Atividades mais recentes
              </h4>
            </v-card-title>
            <v-card-text>
              <section class="timeline">
                <v-layout v-for="a in info.RecentActivities" :key="a.IdUserLog" row wrap align-center class="timeline__item">
                  <v-flex xs2 class="text-left">
                    <div class="timeline--date">
                      <span>{{ a.Date }}</span>
                    </div>
                  </v-flex>
                  <v-flex xs10 class="line text--darkgray pb-3">
                    <p>
                      {{ a.Notes }}
                    </p>
                  </v-flex>
                </v-layout>
              </section>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs5>
          <v-card class="ml-3">
            <v-card-title primary-title class="d-flex d-flex--between">
              <h4 class="font--title font-weight-w500">
                Formações mais recentes
              </h4>
            </v-card-title>
            <v-card-text>
              <div class="d-flex d-flex-between bb-gray py-3" v-for="c in info.RecentCourses" :key="c.Course.IdCourse">
                <div>
                  <v-avatar size="45" class="mr-3 d-inline-block middle">
                    <img :src="GetRelativePath(GetCoursePhoto(c.Course))" />
                  </v-avatar>
                  <div class="d-inline-block middle">
                    <div class="size--s12 font-weight-w500">
                      {{ c.Course.Name }}
                    </div>
                    <p class="size--s12">
                      {{ GetCourseType(c.Course.TypeId) }}
                    </p>
                  </div>
                </div>
                <div class="text-right">
                  <span class="size--s18 font-weight-bold deep-purple--text">
                    {{ c.TotalSubscriptions }}
                  </span>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import UrlBuilder from "@/utilities/url-builder";
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import statisticsService from "@/services/api/statisticsService";
import { GetTypeNameById } from "@/models/course-types";
import router from "@/router/router";
import { minLength } from "vuelidate/lib/validators";
import { layoutServiceBus } from "@/services/bus/layoutServiceBus";

export default {
  data () {
    return {
      info: null,
      Instance: {},
      Trainers: [],
      ClassInstance: {},
      isLoading: true,
      quickActions: [
        {
          title: "Ações de formação",
          path: "classes",
          subtitle: "Adicionar Nova",
          icon: "icon-list",
          help_icon: "icon-help",
          help_text: "Ajuda sobre Formações",
          help_description:
            "Poderá criar uma ação de formação de forma rápida.",
          roles: [2, 4, 5],
        },
        {
          title: "Inscrições",
          path: "inscriptions",
          subtitle: "Adicionar inscrições a ação de formação",
          icon: "icon-catalog-port",
          help_icon: "icon-help",
          help_text: "Ajuda sobre inscrições e presenças",
          help_description: "Poderá criar uma inscrição de forma rápida.",
          roles: [2, 4, 5],
        },
        {
          title: "Calendário de Formação",
          path: "/catalog/calendar",
          subtitle: "Consultar calendário de formações",
          icon: "icon-calendar",
          help_icon: "icon-help",
          help_text: "Ajuda sobre Calendário",
          help_description:
            "Poderá observar um calendário que contém as sessões de formação futuras.",
          roles: [2, 4, 5],
        },
        {
          title: "Relatórios",
          path: "/reports",
          subtitle: "Consultar relatórios e indicadores",
          icon: "icon-catalog-challenge",
          help_icon: "icon-help",
          help_text: "Ajuda sobre Relatórios",
          help_description:
            "Poderá elaborar e/ou observar diversos relatórios referentes à plataforma.",
          roles: [2, 3, 4, 5],
        },
      ],
      adoptionChart: {
        series: [
          {
            type: "pie",
            data: [
              {
                category: "Utilizadores ativos",
                value: 0,
              },
              {
                category: "Utilizadores com subsrições",
                value: 0,
              },
            ],
          },
        ],
      },
      coursesChart: {
        series: [
          {
            type: "pie",
            data: [
              {
                category: "Inscrições ativas",
                value: 0,
              },
              {
                category: "Inscrições concluídas",
                value: 0,
              },
            ],
          },
        ],
      },
    };
  },
  validations: {
    searchQuery: {
      minLength: minLength(3),
    },
  },
  computed: {
    quickActionsFiltered () {
      if (this.user && this.user.IdUserRole) {
        var filtered = this.quickActions.filter((s) =>
          s.roles.includes(this.user.IdUserRole)
        );
        return filtered;
      }
      return this.quickActions;
    },
    ...mapGetters({
      user: types.GET_USER,
    }),
  },
  methods: {
    navigateTo (path) {
      this.$router.push(path);
    },
    checkRoleAccess (roles) {
      var role = 0;
      if (this.user) {
        role = this.user.IdUserRole;
        return roles.find(function (element) {
          return element == role;
        })
          ? true
          : false;
      }
      return false;
    },
    openDialogCreateCourseClass () {
      layoutServiceBus.openQuickActionCourseClass();
    },
    openDialogCreateBatchInscription () {
      layoutServiceBus.openQuickActionBatchInscriptions();
    },
    onQuickActionNavigate (link) {
      router.push(`${link.path}#${new Date().getTime()}`);
    },
    GetRelativePath (path) {
      return `${UrlBuilder.GetPath(path)}`;
    },
    GetCourseType (id) {
      return GetTypeNameById(id);
    },
    GetCoursePhoto (course) {
      return course.PhotoSrc ? course.PhotoSrc : `/assets/imgs/no-photo.png`;
    },
  },
  async created () {
    // console.log("User object:", this.user);
    await statisticsService
      .Dashboard()
      .then((res) => {
        this.info = res.data;
        this.coursesChart.series[0].data[0].value =
          this.info.TotalActiveSubscriptions;
        this.coursesChart.series[0].data[1].value =
          this.info.TotalCompletedSubscriptions;
        this.adoptionChart.series[0].data[0].value = this.info.TotalActiveUsers;
        this.adoptionChart.series[0].data[1].value =
          this.info.TotalActiveUsersWithSubscriptions;
      })
      .catch(() => {
        // console.error('Error in Axios request:', error);
        // console.log('Axios response details:');
        // console.log(error.response);
        // Handle the error or log it
      })
      .finally(() => (this.isLoading = false));
  },
};
</script>

<style scoped lang="scss">
@import "../../styles/variables.scss";

.justify-content-center {
  justify-content: center;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.v-card__text {
  padding: 25px 25px !important;
}

.v-card__title,
.v-card__actions {
  cursor: pointer;
}

.list__box {
  // padding: 0 25px;
  background-color: $white;

  &__details {
    padding: 25px 25px;

    &__item {
      padding: 12px 0;

      h4 {
        color: $gray-950;
        font-weight: 500 !important;
      }

      p {
        margin-bottom: 0;
        color: $gray-950;
      }
    }
  }

  .br {
    border-right: 1px solid #eaeaea;
  }
}

.border-ouline {
  border: 1px solid #ebedf2;
  border-radius: $border-radius !important;
  font-size: 14px !important;
  text-transform: unset !important;
  font-family: $font-family-base !important;
  outline: none !important;
  font-weight: 400 !important;
  padding: 6px 25px !important;
  margin: 0 !important;
  transition: 0.3s;

  &:hover {
    background-color: #ebedf2;
  }
}

.timeline {
  &__item {
    .line {
      position: relative;
      padding: 25px 0;

      &::before {
        content: "";
        width: 2px;
        position: absolute;
        top: 0;
        left: -30px;
        display: block;
        height: 100%;
        background-color: $gray-300;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: -36px;
        display: block;
        background-color: $gray-300;
        border-radius: 15px;
        width: 15px;
        height: 15px;
      }
    }
  }
}
</style>
